import { getAuth } from 'firebase/auth';
import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import handleAxiosError from './error-handler';

const setAccessToken = (token?: string) => ({
  Authorization: `Bearer ${token}`,
});

const getAccessToken = async () => {
  const auth = getAuth();
  const currentUserIdToken = await auth.currentUser?.getIdToken();
  return setAccessToken(currentUserIdToken);
};

const setAdditionalHeaders = async () => ({
  Accept: 'application/json, text/plain, */*',
  'Content-Type': 'application/json;charset=utf-8',
  timeout: 1000 * 540,
});

const axiosInstance = async (config: AxiosRequestConfig): Promise<AxiosInstance> => {
  const axiosConfig: AxiosRequestConfig = {
    baseURL: process.env.VUE_APP_BASE_URL,
    ...config,
    headers: {
      ...config.headers,
      ...setAdditionalHeaders(),
      ...await getAccessToken(),
    },
  };
  return axios.create(axiosConfig);
};

const transformResponse = async (_axios: any) => _axios
  .then(async (res: any) => res.data)
  .catch(async (err: any) => {
    handleAxiosError(err);
  });

const get = async (url: string, config: any) => transformResponse(
  (await axiosInstance(config))
    .get(url),
);

const post = async (url: string, data: any, config: any) => transformResponse(
  (await axiosInstance(config))
    .post(url, data),
);

const put = async (url: string, data: any, config: any) => transformResponse(
  (await axiosInstance(config))
    .put(url, data),
);

const del = async (url: string, config: any) => transformResponse(
  (await axiosInstance(config))
    .delete(url),
);

const patch = async (url: string, data: any, config: any) => transformResponse(
  (await axiosInstance(config))
    .patch(url, data),
);

export {
  get, post, put, patch, del,
};
