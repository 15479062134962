import { computed } from 'vue';
import { useRouter } from 'vue-router';
import store from '@/store';
import { HomeBuyer, StoreActions } from '@/constants';
import { checkStringEquality, useAuth } from './funcs';
import { ReqHomesDocument } from '@/models';

const useHome = () => {
  const allHomes = computed(() => store.getters.allHomes as ReqHomesDocument[] || []);
  const home = computed(() => store.getters.selectedHome as ReqHomesDocument || '');
  const buyerId = computed(() => store.getters.homebuyerId as string || '');
  if (!home.value && !buyerId.value) {
    useRouter().push({ name: 'Home' });
  }
  const isAdditionUser = computed(() => (Object.keys(home.value).length && home.value.homebuyers.some((user: any) => checkStringEquality(user.type, HomeBuyer.ADDITIONAL_USER)
    && buyerId.value === user.homeBuyerId)));

  const loggedInBuyerType = computed(() => home.value.homebuyers && (home.value.homebuyers.find((buyer) => buyer.homeBuyerId === buyerId.value))?.type);
  return {
    isAdditionUser,
    selectedHome: home,
    allHomes,
    loggedInBuyerType,
  };
};

const getHomeLastAnnouncementRead = () => {
  const { selectedHome } = useHome();
  const { lastAnnouncementRead } = selectedHome.value;
  return lastAnnouncementRead || '';
};

const setHomeLastAnnouncementRead = (value: string) => {
  const { selectedHome, allHomes } = useHome();
  selectedHome.value.lastAnnouncementRead = value;
  const homes = allHomes.value.map((home: any) => {
    if (home.id === selectedHome.value.id) {
      home.lastAnnouncementRead = value;
    }
    return home;
  });
  store.dispatch(StoreActions.SET_HOMES, homes);
  store.dispatch(StoreActions.SET_SELECTED_HOME, selectedHome.value);
};

export default { useHome, getHomeLastAnnouncementRead, setHomeLastAnnouncementRead };
