const noDocumentsMessage = 'No documents are available to display.';

const imageFormats = ['image/jpeg', 'image/jpg', 'image/gif', 'image/png', 'image/raw', 'image/cr2', 'image/nef', 'image/orf', 'image/sr2', 'image/tif', 'image/tiff'];

/* eslint-disable */
const specialCharacters = `/[!@#$%^&*()_+-=[]{};':"|,.<>?]/;`;

export {
  noDocumentsMessage,
  specialCharacters,
  imageFormats,
};
