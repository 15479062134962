import { createStore } from 'vuex';
import { ref } from 'vue';
import {
  Homebuyer, IConversation, ICheckList, ITimeline, IMortage, ReqHomesDocument, IAppointementFirestore,
} from '@/models';
import { StoreActions } from '@/constants';

export default createStore({
  state: {
    loading: false,
    homebuyer: {} as Homebuyer,
    homes: [] as ReqHomesDocument[],
    homebuyerId: '',
    messageSkeleton: false,
    announcementSkeleton: false,
    messages: ref<IConversation[]>([]),
    messageListner: null as any,
    conversationSubscribers: [] as any,
    messageListenerActivated: false,
    timeline: {} as ITimeline,
    checklists: [] as ICheckList[],
    mortage: {} as IMortage,
    selectedHome: {} as ReqHomesDocument,
    errorCode: -1,
    appointmentReceived: false,
    appointments: [] as IAppointementFirestore[],
    maxAdditionalUsersAllowed: -1,
    specialCharactersNotAllowed: '',
    appointmentsSkeleton: false,
    unseenPosts: 0,
    isLoginAsAdmin: false,
    adminToken: '',
  },
  getters: {
    messageSkeleton: (state) => state.messageSkeleton,
    announcementSkeleton: (state) => state.announcementSkeleton,
    appointmentsSkeleton: (state) => state.appointmentsSkeleton,
    messageListener: (state) => state.messageListenerActivated,
    conversation: (state) => state.messages,
    showLoader: (state) => state.loading,
    homebuyer: (state) => state.homebuyer,
    homebuyerId: (state) => state.homebuyerId,
    selectedHome: (state) => state.selectedHome,
    getTimeline: (state) => state.timeline,
    getCheckLists: (state) => state.checklists,
    getMortage: (state) => state.mortage,
    errorCode: (state) => state.errorCode,
    getAppointments: (state) => state.appointments,
    hasReceivedAppointments: (state) => state.appointmentReceived,
    community: (state) => state.selectedHome.community,
    division: (state) => state.selectedHome.division,
    allHomes: (state) => state.homes,
    maxAdditionalUsersAllowed: (state) => state.maxAdditionalUsersAllowed,
    specialCharactersNotAllowed: (state) => state.specialCharactersNotAllowed,
    unseenPosts: (state) => state.unseenPosts,
    isLoginAsAdmin: (state) => state.isLoginAsAdmin,
    adminToken: (state) => state.adminToken,
  },
  mutations: {
    updateMessages(state: any, value: IConversation[]) {
      state.messages = value;
      state.messageListenerActivated = true;
    },
    updateMessageListener(state, value: boolean) {
      state.messageListenerActivated = value;
    },
    updateLoader(state, value: boolean) {
      state.loading = value;
    },
    setBuyer(state, buyer: Homebuyer) {
      state.homebuyer = buyer;
    },
    setMaxAdditionalUsersAllowed(state, value: number) {
      state.maxAdditionalUsersAllowed = value;
    },
    setSpecialCharactersNotAllowed(state, value: string) {
      state.specialCharactersNotAllowed = value;
    },
    setSelectedHome(state, selectedHome: ReqHomesDocument) {
      state.selectedHome = selectedHome;
    },
    setBuyerId(state, homeBuyerId: string) {
      state.homebuyerId = homeBuyerId;
    },
    setTimeline(state, timelineData: ITimeline) {
      state.timeline = timelineData;
    },
    setChecklist(state, checkListData: ICheckList) {
      state.checklists.push(checkListData);
    },
    setMortage(state, mortageData: IMortage) {
      state.mortage = mortageData;
    },
    setErrorCode(state, errorCode: number) {
      state.errorCode = errorCode;
    },
    setIsLoginAsAdmin(state, data: boolean) {
      state.isLoginAsAdmin = data;
    },
    setAppointments(state, data: IAppointementFirestore[]) {
      state.appointments = data;
      state.appointmentReceived = true;
    },
    clearStore(state) {
      state.loading = false;
      state.homebuyer = {} as Homebuyer;
      state.messages = ref<IConversation[]>([]);
      state.homebuyerId = '';
      state.selectedHome = {} as ReqHomesDocument;
      state.homes = [] as ReqHomesDocument[];
      state.maxAdditionalUsersAllowed = -1;
      state.specialCharactersNotAllowed = '';
      state.errorCode = -1;
      state.isLoginAsAdmin = false;
      state.adminToken = '';
    },
    unmountListener(state) {
      if (state.messageListenerActivated) {
        state.messageListner();
        state.conversationSubscribers.forEach((element: any) => {
          element();
        });
      }
      state.messageListenerActivated = false;
      state.conversationSubscribers = [];
    },
    async resetHomeData(state) {
      state.timeline = {} as ITimeline;
      state.checklists = [] as ICheckList[];
      state.mortage = {} as IMortage;
      state.appointmentReceived = false;
      state.appointments = [] as IAppointementFirestore[];
      state.messageSkeleton = false;
      state.announcementSkeleton = false;
    },
    setMessageListener(state, listener) {
      state.messageListner = listener;
    },
    setConversationListener(state, listener) {
      state.conversationSubscribers = listener;
    },
    setHomes(state, homes: ReqHomesDocument[]) {
      state.homes = homes;
    },
    setMessagesSkeleton(state, value: boolean) {
      state.messageSkeleton = value;
    },
    setAnnouncementSkeleton(state, value: boolean) {
      state.announcementSkeleton = value;
    },
    setAppointmentsSkeleton(state, value: boolean) {
      state.appointmentsSkeleton = value;
    },
    setUnseenPostsCount(state, value: number) {
      state.unseenPosts = value;
    },
    setAdminToken(state, value: string) {
      state.adminToken = value;
    },
  },
  actions: {
    updateMessages({ commit }, value: IConversation[]) {
      commit(StoreActions.UPDATE_MESSAGES, value);
    },
    updateMessageListener({ commit }, value: IConversation[]) {
      commit(StoreActions.UPDATE_MESSAGE_LISTENER, value);
    },
    updateLoader({ commit }, value: boolean) {
      commit(StoreActions.UPDATE_LOADER, value);
    },
    setBuyer({ commit }, buyer: Homebuyer) {
      commit(StoreActions.SET_BUYER, buyer);
    },
    setTimeline({ commit }, timelineData: ITimeline) {
      commit(StoreActions.SET_TIMELINE, timelineData);
    },
    setChecklist({ commit }, checkListData: ICheckList) {
      commit(StoreActions.SET_CHECKLIST, checkListData);
    },
    setMortage({ commit }, mortageData: IMortage) {
      commit(StoreActions.SET_MORTAGE, mortageData);
    },
    clearStore({ commit }) {
      commit(StoreActions.RESET_HOME_DATA);
      commit(StoreActions.CLEAR_STORE);
      commit(StoreActions.UNMOUNT_LISTENER);
    },
    resetHomeData({ commit }) {
      commit(StoreActions.RESET_HOME_DATA);
      commit(StoreActions.UNMOUNT_LISTENER);
    },
    unmountListener({ commit }) {
      commit(StoreActions.UNMOUNT_LISTENER);
    },
    setBuyerId({ commit }, homeBuyerId: string) {
      commit(StoreActions.SET_BUYER_ID, homeBuyerId);
    },
    setSelectedHome({ commit }, selectedHome: ReqHomesDocument) {
      commit(StoreActions.SET_SELECTED_HOME, selectedHome);
    },
    setErrorCode({ commit }, errorCode: number) {
      commit(StoreActions.SET_ERROR_CODE, errorCode);
    },
    async setMessageListener({ commit }, listener: any) {
      await commit(StoreActions.SET_MESSAGE_LISTENER, listener);
    },
    setAppointments({ commit }, appointment: IAppointementFirestore[]) {
      commit(StoreActions.SET_APPOINTMENTS, appointment);
    },
    setIsLoginAsAdmin({ commit }, data: boolean) {
      commit(StoreActions.SET_IS_LOGIN_AS_ADMIN, data);
    },
    setAppointmentsSkeleton({ commit }, value: boolean) {
      commit(StoreActions.SET_APPOINTMENTS_SKELETON, value);
    },
    async setConversationListener({ commit }, listener: any) {
      await commit(StoreActions.SET_CONVERSATION_LISTENER, listener);
    },
    setHomes({ commit }, homes: ReqHomesDocument[]) {
      commit(StoreActions.SET_HOMES, homes);
    },
    setMessagesSkeleton({ commit }, value: boolean) {
      commit(StoreActions.SET_MESSAGES_SKELETON, value);
    },
    setAnnouncementSkeleton({ commit }, value: boolean) {
      commit(StoreActions.SET_ANNOUNCEMENTS_SKELETON, value);
    },
    setMaxAdditionalUsersAllowed({ commit }, value: number) {
      commit(StoreActions.SET_MAX_ADDITIONAL_USERS_ALLOWED, value);
    },
    setSpecialCharactersNotAllowed({ commit }, value: string) {
      commit(StoreActions.SET_SPECIAL_CHARACTERS_NOT_ALLOWED, value);
    },
    setUnseenPostsCount({ commit }, value: number) {
      commit(StoreActions.UNSEEN_POSTS, value);
    },
    setAdminToken({ commit }, value: string) {
      commit(StoreActions.SET_ADMIN_TOKEN, value);
    },
  },
  modules: {
  },
});
