import { buyerDetails } from '@/api';
import { StoreActions } from '@/constants';
import { HomeBuyerDetails, ReqHomesDocument, IHomebuyer } from '@/models';
import store from '@/store';
import { checkStringEquality, getImpersonationParams } from './funcs';

const setSelectedHomeData = async (home: ReqHomesDocument) => {
  await store.dispatch(StoreActions.SET_SELECTED_HOME, home);
  localStorage.setItem('selectedHomeId', home.id);
};

const setBuyerData = async (homeBuyer: IHomebuyer, homeBuyerId: string, homes: any,
  maxAdditionalUsersAllowed: number, specialCharactersNotAllowed: string) => {
  await store.dispatch(StoreActions.SET_BUYER_ID, homeBuyerId);
  await store.dispatch(StoreActions.SET_BUYER, homeBuyer);
  await store.dispatch(StoreActions.SET_HOMES, homes);
  store.dispatch(StoreActions.SET_MAX_ADDITIONAL_USERS_ALLOWED, maxAdditionalUsersAllowed);
  store.dispatch(StoreActions.SET_SPECIAL_CHARACTERS_NOT_ALLOWED, specialCharactersNotAllowed);
};

const getBuyerDetails = async (email: string) => {
  let totalHomes = 0;
  const response: HomeBuyerDetails = await buyerDetails(getImpersonationParams({
    email: email.toLowerCase(),
  }));
  if (response) {
    const {
      homeBuyer, homeBuyerId, homes, maxAdditionalUsersAllowed, specialCharacterNotAllowed,
    } = response;
    store.dispatch(StoreActions.UNSEEN_POSTS, homeBuyer.unseenPosts);
    const { reqHomesDocument } = homes;
    totalHomes = homes.totalHomes;
    const selectedHomeId = localStorage.getItem('selectedHomeId');
    await setBuyerData(homeBuyer, homeBuyerId, homes.reqHomesDocument, maxAdditionalUsersAllowed, specialCharacterNotAllowed);
    if (selectedHomeId) {
      const selectedHome = reqHomesDocument.find((home) => checkStringEquality(home.id, selectedHomeId));
      if (selectedHome) {
        setSelectedHomeData(selectedHome);
      }
    }
    if (totalHomes === 1) {
      setSelectedHomeData(reqHomesDocument[0]);
    }
  }
};

export default { getBuyerDetails, setSelectedHomeData };
