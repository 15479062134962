import { Conversation, ConversationAdminData } from './response/conversations';

export interface IAdminDocument {
  id: string;
  firstName: string;
  lastName: string;
  roles: string[];
  email: string;
  designation: string;
  conversationFlag: boolean;
  status: boolean;
}

export class Admin {
  id: string;

  firstName: string;

  lastName: string;

  email: string;

  conversation?: Conversation;

  roles: string[];

  designation: string;

  conversationFlag: boolean;

  status: boolean;

  allReadMessagesFlag = false;

  constructor(data: IAdminDocument) {
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.id = data.id;
    this.email = data.email;
    this.roles = data.roles;
    this.designation = data.designation;
    this.conversationFlag = data.conversationFlag;
    this.status = data.status;
  }

  public set addCoversation(value: Conversation) {
    this.conversation = value;
  }

  static getAdminData(adminData: ConversationAdminData): IAdminDocument {
    return {
      id: adminData.id,
      firstName: adminData.firstName,
      lastName: adminData.lastName,
      email: adminData.email,
      roles: adminData.roles,
      designation: adminData.designation || '',
      conversationFlag: false,
      status: false,
    };
  }
}
