import { IMortage, ITimelineResponse, ICheckList } from '@/models';
import { get } from './base-api';

const getTimeline = (homeId: string, params = {}): Promise<ITimelineResponse> => get(`/userTimeline/${homeId}`, { params });

const getChecklist = (checkListId: string, params = {}): Promise<ICheckList> => get(`/userTimeline/buyer/${checkListId}`, { params });

const getMortage = (params = {}): Promise<IMortage> => get('mortage/buyer', { params });

export { getTimeline, getChecklist, getMortage };
