const WarrantyModal = () => ({
  title: 'Submit your warranty?',
  description: 'You cannot make any more changes to this warranty after you submit.',
  cancelActionText: 'Cancel',
  confirmActionText: 'Yes, Submit Warranty',
});

const SignOutModal = () => ({
  title: 'You are about to log out',
  description: 'Are you sure you want to log out?',
  cancelActionText: 'Cancel',
  confirmActionText: 'Log Out',
});

const DeleteIssueModal = () => ({
  title: 'You are about to delete the issue',
  description: 'Are you sure you want to delete this issue?',
  cancelActionText: 'Cancel',
  confirmActionText: 'Delete',
});

const Modals = {
  Warranty: WarrantyModal(),
  SignOut: SignOutModal(),
  Delete: DeleteIssueModal(),
};

const Alert = {
  Success: 'We have saved your changes',
  EmailSent: 'Email sent successfully',
  InviteSent: 'Invite sent successfully',
  UserNotExist: "This email doesn't exist",
  Error: 'Something went wrong',
  EmailError: 'There was some error in sending the email. Please try again after some time',
  BuyerAlreadyExist: 'This user already exists in the system, we have notified them that you have added them in your home',
  WarrantyNotSubmitted: 'There was some error in submitting warranty. Please contact William Ryan Homes for additional support.',
  BuyerNotVerified: 'This buyer is not verified',
  AdditionalBuyerError: 'Primary/Co-buyer email cannot be used for Additional Buyer.',
};

export {
  Modals,
  Alert,
};
