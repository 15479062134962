enum Loader {
  HIDE = 'Hide',
  SHOW = 'Show',
}

enum Response {
  SUCCESS = 'success',
  SENT = 'email-sent-successfully',
  INVITESENT = 'invite-sent-successfully',
  USERNOTFOUND = 'UserNotFound',
  EMAILERROR = 'sending-email-error',
  ERROR = 'error',
  USER_ALREADY_EXIST = 'buyer-already-exist',
  WARRANTY_SUBMISSION_NOT_ALLOWED = 'Warranty submission not allowed',
  BUYER_NOT_VERIFIED = 'Invalid Buyer',
  ADDITIONAL_BUYER_ERROR = 'additional-buyer-error',
}

enum StatusCode {
  BADREQUEST = 400,
  UNAUTHORIZED = 401,
  NOTFOUND = 404,
  ISE = 500,
}

enum MyAccountTab {
  UPDATE_CONTACT_INFO = 'update-contact-info',
  CHANGE_PASSWORD = 'change-password',
  ADD_ADDITIONAL_USER = 'add-additional-user',
  TRANSFER_WARRANTY = 'transfer-warranty',
}

enum HomeBuyer {
  ADDITIONAL_USER = 'Additional User',
  CO_BUYER = 'Co Buyer',
  BUYER = 'Buyer',
}

enum MyTimeLineStatus {
  PENDING = 'pending',
  CURRENT = 'current',
  DONE = 'done',
  SELECTED = 'selected',
}

enum Milestones {
  CONTRACT = 'contract',
  FINANCE = 'financing',
  SELECTIONS = 'selections',
  CONSTRUCTION = 'construction',
  OWNERSHIP = 'ownership',
}

enum SenderType {
  ADMIN = 'admin',
  BUYER = 'buyer',
}

enum WarrantyType {
  SIXTY_DAYS = '60 days',
  ELEVEN_MONTHS = '11 months',
}

enum MessagesSection {
  ANNOUNCEMENTS = 'announcement',
  CONVERSATIONS = 'conversation',
}

enum Collections {
  HOMES = 'homes',
  HOMEBUYERS = 'homebuyers',
  SETTINGS = 'settings',
  TOTAL_RECORDS = 'totalRecords',
  ANNOUNCEMENTS = 'announcements',
  CONVERSATIONS = 'conversations',
  MESSAGES = 'messages',
}

enum Routes {
  LOGIN = 'Login',
  RESET_PASSWORD = 'Forgot',
  DASHBOARD = 'Dashboard',
  HOMES = 'Home',
  TIMELINE = 'Timeline',
  MESSAGES = 'Messages',
  PHOTOS = 'Photos',
  DOCUMENTS = 'Documents',
  WARRANTY = 'Warranty',
  MY_ACCOUNT = 'My Account',
  ERROR = '404 Page',
  CONTACT_US = 'contact-us'
}

enum StoreActions {
  UPDATE_MESSAGES = 'updateMessages',
  UPDATE_MESSAGE_LISTENER = 'updateMessageListener',
  UPDATE_LOADER = 'updateLoader',
  SET_BUYER = 'setBuyer',
  SET_MAX_ADDITIONAL_USERS_ALLOWED = 'setMaxAdditionalUsersAllowed',
  SET_SELECTED_HOME = 'setSelectedHome',
  SET_BUYER_ID = 'setBuyerId',
  SET_TIMELINE = 'setTimeline',
  SET_CHECKLIST = 'setChecklist',
  SET_MORTAGE = 'setMortage',
  SET_ERROR_CODE = 'setErrorCode',
  SET_APPOINTMENTS = 'setAppointments',
  CLEAR_STORE = 'clearStore',
  RESET_HOME_DATA = 'resetHomeData',
  SET_MESSAGE_LISTENER = 'setMessageListener',
  SET_CONVERSATION_LISTENER = 'setConversationListener',
  SET_HOMES = 'setHomes',
  SET_MESSAGES_SKELETON = 'setMessagesSkeleton',
  SET_ANNOUNCEMENTS_SKELETON = 'setAnnouncementSkeleton',
  SET_APPOINTMENTS_SKELETON = 'setAppointmentsSkeleton',
  SET_SPECIAL_CHARACTERS_NOT_ALLOWED = 'setSpecialCharactersNotAllowed',
  UNMOUNT_LISTENER = 'unmountListener',
  UNSEEN_POSTS = 'setUnseenPostsCount',
  SET_IS_LOGIN_AS_ADMIN = 'setIsLoginAsAdmin',
  SET_ADMIN_TOKEN= 'setAdminToken',
}

enum Issues {
  maxIssuesCount = 30,
}

export {
  Loader, Response, StatusCode, MyAccountTab, HomeBuyer, MyTimeLineStatus, Milestones, SenderType, WarrantyType, MessagesSection, Collections, Routes,
  StoreActions, Issues,
};
