import { get, post } from './base-api';

const getImgUrl = (params = {}) => get('/getDownloadUrl', { params });

const getPosts = (body = {}) => post('/photos', body, {});

const getDesignCentreImages = (divisionId: string, params = {}) => get(`/photos/designCenter/${divisionId}`, { params });

const getEnvisionAndVideoLink = (params = {}) => get('documents/link', { params });

export {
  getImgUrl, getPosts, getDesignCentreImages, getEnvisionAndVideoLink,
};
