import { DocumentData, DocumentReference, DocumentSnapshot } from 'firebase/firestore';
import { Ref } from 'vue';
import { ConversationHomeData } from '../home';
import { IConversationMessages } from '../message';

export interface ConversationBuyerData {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  homePhone: string;
}

export interface ConversationAdminData extends Omit<ConversationBuyerData, 'mobilePhone' | 'homePhone'> {
  designation: string;
  roles: string[];
}

export interface ConversationUnreads {
  admin: number;
  buyer: number;
}

export interface IConversations {
  id: string;
  adminReference: DocumentReference;
  buyerReference: DocumentReference;
  homeReference: DocumentReference;
  divisionReference: DocumentReference;
  communityReference: DocumentReference;
  adminData: ConversationAdminData;
  buyerData: ConversationBuyerData;
  homeData: ConversationHomeData;
  lastMessageDateTime: string;
  firstMessageDateTime: string;
  messages: Ref<DocumentSnapshot<DocumentData>[]>;
  unreadMessages: ConversationUnreads;
}
export interface IConversation {
  id: string;
  adminReference: DocumentReference;
  buyerReference: DocumentReference;
  homeReference: DocumentReference;
  divisionReference: DocumentReference;
  communityReference: DocumentReference;
  adminData: ConversationAdminData;
  buyerData: ConversationBuyerData;
  homeData: ConversationHomeData;
  lastMessageDateTime: string;
  firstMessageDateTime: string;
  messages: DocumentSnapshot<DocumentData>[];
  unreadMessages: ConversationUnreads;
}

export class Conversation {
  public id: string;

  public lastMessageDate: string;

  public messages: DocumentSnapshot<DocumentData>[];

  public unreadCount: number;

  constructor(data: IConversation) {
    this.id = data.id;
    this.lastMessageDate = data.lastMessageDateTime;
    this.messages = data.messages;
    this.unreadCount = data.unreadMessages.buyer;
  }
}
