import { FirebaseApp, initializeApp } from 'firebase/app';
import {
  initializeAuth, browserLocalPersistence,
} from 'firebase/auth';

export const firebaseConfig = {
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_APPID,
  measurementId: process.env.VUE_APP_MEASUREMENT_ID,
};

let firebaseApp: FirebaseApp;

export const init = () => {
  if (!firebaseApp) {
    firebaseApp = initializeApp(firebaseConfig);
    initializeAuth(firebaseApp, {
      persistence: browserLocalPersistence,
    });
  }
};
