import {
  doc, DocumentData, DocumentReference, DocumentSnapshot, getFirestore,
} from 'firebase/firestore';
import { ConversationHomeData, Home, HomeData } from './home';
import { ConversationMessages, IChat, IConversationMessages } from './message';
import {
  ConversationAdminData, ConversationBuyerData, ConversationUnreads, IConversation,
} from './response/conversations';

export interface ConversationMessageFirestoreDocument {
  count: number;
  messages: IChat[];
  createdAt: string;
}
export class FirestoreConversation implements IConversation {
  id!: string;

  adminReference!: DocumentReference;

  buyerReference!: DocumentReference;

  homeReference!: DocumentReference;

  divisionReference!: DocumentReference;

  communityReference!: DocumentReference;

  adminData!: ConversationAdminData;

  buyerData!: ConversationBuyerData;

  homeData!: ConversationHomeData;

  lastMessageDateTime!: string;

  firstMessageDateTime!: string;

  messages!: DocumentSnapshot<DocumentData>[];

  unreadMessages!: ConversationUnreads;

  constructor(data: IConversation) {
    Object.assign(this, data);
  }

  static toFirestore(adminData: ConversationAdminData, buyerData: ConversationBuyerData, homeData: HomeData): DocumentData {
    const adminRef = doc(getFirestore(), `admins/${adminData.id}`);
    const buyerRef = doc(getFirestore(), `homebuyers/${buyerData.id}`);
    const homeRef = doc(getFirestore(), `homes/${homeData.id}`);
    const homeDataToCreate = Home.toFirestore(homeData);
    return {
      adminReference: adminRef,
      buyerReference: buyerRef,
      homeReference: homeRef,
      divisionReference: homeData.divisionReference,
      communityReference: homeData.communityReference,
      adminData,
      buyerData,
      homeData: homeDataToCreate,
      firstMessageDateTime: '',
      unreadMessages: {
        admin: 0,
        buyer: 0,
      },
    };
  }
}
