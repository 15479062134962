import { ComputedRef } from 'vue';
import {
  createRouter, createWebHistory, LocationQuery, NavigationGuardNext, RouteLocationNormalized, RouteRecordName,
} from 'vue-router';
import { getAuth, signOut } from 'firebase/auth';
import { MyAccountTab, Routes, StoreActions } from '@/constants';
import { useAuth } from '@/util/funcs';
import Buyer from '@/util/homebuyer';
import Router from './routes';
import store from '@/store';
import home from '@/util/home';
import { ReqHomesDocument } from '@/models';

const { routes } = Router;

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

const getHomeDetails = async (user: any) => {
  if (localStorage.getItem('isLoggedInAsAdmin') === 'true') {
    store.dispatch(StoreActions.SET_IS_LOGIN_AS_ADMIN, true);
  }
  const { homebuyer } = store.getters;
  if (user.value && user.value.email && !Object.keys(homebuyer).length) {
    await Buyer.getBuyerDetails(user.value.email);
  }
};

const homeSelectionNotRequired = (name: string, query: LocationQuery) => name === Routes.MY_ACCOUNT && query.tab !== MyAccountTab.ADD_ADDITIONAL_USER;

const checkMultipleHomes = async (next: any, homes: ComputedRef<ReqHomesDocument[]>) => {
  if (homes.value.length !== 1) {
    next();
  } else {
    next({ name: Routes.DASHBOARD });
  }
};

const checkSelectedHome = async (to: any, next: any, selectedHome: ComputedRef<ReqHomesDocument>) => {
  if (Object.keys(selectedHome.value).length || homeSelectionNotRequired(to.name, to.query)) {
    next();
  } else {
    next({ name: Routes.HOMES });
  }
};

const navigationState = async (requiresAuth: boolean, requiresRedirect: boolean, name: RouteRecordName | null | undefined, query: LocationQuery) => {
  const { user, isEmailVerified } = await useAuth();
  const isAuthenticated = !!user.value;
  return {
    unauthorizedPageAccess: name === 'contact-us' ? false : requiresAuth && !isAuthenticated,
    accessNotAllowed: name === 'contact-us' ? false : requiresRedirect && isAuthenticated,
    passwordChangeRequired: isAuthenticated && !isEmailVerified.value && name !== Routes.MY_ACCOUNT && query.tab !== MyAccountTab.CHANGE_PASSWORD,
  };
};

const getRedirectPath = () => ({
  unauthorizedPageAccess: { name: Routes.LOGIN },
  accessNotAllowed: { name: Routes.DASHBOARD },
  passwordChangeRequired: {
    name: Routes.MY_ACCOUNT,
    query: {
      tab: MyAccountTab.CHANGE_PASSWORD,
    },
  },
});

const checkHomeRequirements = (to: RouteLocationNormalized, next: NavigationGuardNext) => {
  const { selectedHome, allHomes } = home.useHome();
  if (to.name === Routes.HOMES) {
    checkMultipleHomes(next, allHomes);
  } else {
    checkSelectedHome(to, next, selectedHome);
  }
};

const handleRouteNavigations = async (to: RouteLocationNormalized, next: NavigationGuardNext, routeState: any) => {
  const { user } = await useAuth();
  const activeState = Object.keys(routeState).find((key) => routeState[key] === true) || '';
  await getHomeDetails(user);
  if (activeState) {
    const path = (getRedirectPath() as any)[activeState];
    next(path);
  } else if (user.value && store.getters.homebuyerId) {
    checkHomeRequirements(to, next);
  } else {
    next();
  }
};

const redirectToLogin = () => {
  localStorage.clear();
  router.push({ name: Routes.LOGIN });
};

const checkAdminSession = (to: RouteLocationNormalized) => {
  if (to.query.token) {
    store.dispatch(StoreActions.CLEAR_STORE);
    signOut(getAuth()).then(async () => {
      redirectToLogin();
    }).catch((err) => {
      redirectToLogin();
    });
    store.dispatch(StoreActions.SET_ADMIN_TOKEN, to.query.token as string);
    localStorage.clear();
  }
};

router.beforeEach(async (to, from, next) => {
  try {
    checkAdminSession(to);
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const requiresRedirect = to.matched.some((record) => record.meta.requiresRedirect);
    const routeState = await navigationState(requiresAuth, requiresRedirect, to.name, to.query);
    await handleRouteNavigations(to, next, routeState);
  } catch {
    localStorage.clear();
    store.dispatch(StoreActions.CLEAR_STORE);
    next(Routes.LOGIN);
  }
});

export default router;
