import { post, get } from './base-api';

const getWarrantyDetails = (homeId: string, params = {}) => get(`warranty/getWarranty/${homeId}`, { params });

const addNewWarrantyIssue = (body = {}) => post('warranty/addNewIssue', body, {});

const addNewWarranty = (body = {}) => post('warranty/addNewWarranty', body, {});

const deleteWarrantyIssue = (body = {}) => post('warranty/deleteWarrantyIssue', body, {});

const editWarrantyIssue = (body = {}) => post('warranty/editIssue', body, {});

const getWarrntyPhotos = (params = {}) => get('warranty/assets/images', params);

const getWarrntyVideo = (params = {}) => get('warranty/assets/video', params);

const submitCurrentWarranty = (body = {}) => post('warranty/submitWarranty', body, {});

export {
  getWarrantyDetails,
  addNewWarrantyIssue,
  addNewWarranty,
  editWarrantyIssue,
  deleteWarrantyIssue,
  submitCurrentWarranty,
  getWarrntyPhotos,
  getWarrntyVideo,
};
