import { AxiosError } from 'axios';
import { StatusCode, StoreActions } from '@/constants';
import store from '@/store';
import { showLoader } from '@/util/funcs';

const showErrorPage = (errorCode: number) => {
  store.dispatch(StoreActions.SET_ERROR_CODE, errorCode);
};
const handleErrorStatus = (code: number) => {
  switch (true) {
    case code >= StatusCode.ISE:
      showErrorPage(StatusCode.ISE);
      break;
    case code >= StatusCode.NOTFOUND:
      break;
    case code >= StatusCode.BADREQUEST:
      showErrorPage(StatusCode.BADREQUEST);
      break;
    default:
      throw new Error();
  }
};
export default function handleAxiosError(error: AxiosError) {
  showLoader(false);
  if (error.response) {
    handleErrorStatus(error.response.status);
  } else if (error.request) {
    handleErrorStatus(error.request.status);
  } else {
    throw new Error(error.message);
  }
}
