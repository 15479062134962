import { Ref, ref } from 'vue';
import {
  getFirestore, doc, getDoc, onSnapshot, collection, query,
  where, setDoc, updateDoc, DocumentReference, DocumentSnapshot,
  DocumentData, orderBy, limit, Query, startAfter, getDocs,
} from 'firebase/firestore';
import {
  ConversationMessageFirestoreDocument, FirestoreConversation, HomeData,
  IChat, ConversationAdminData, ConversationBuyerData,
  IConversation, IConversations,
} from '@/models';
import { Collections, StoreActions } from '@/constants';
import store from '@/store';

export const getFullDate = (date = ''): string => date.split('T')[0];

const paginateMessage = (conversationId: string, reteriveCounts: number, lastDocument?: DocumentSnapshot<DocumentData>) => {
  let queryResponse: Query<DocumentData>;
  if (lastDocument) {
    queryResponse = query(collection(getFirestore(), Collections.CONVERSATIONS, conversationId, Collections.MESSAGES),
      orderBy('createdAt', 'desc'), startAfter(lastDocument), limit(reteriveCounts));
  } else {
    queryResponse = query(collection(getFirestore(), Collections.CONVERSATIONS, conversationId, Collections.MESSAGES),
      orderBy('createdAt', 'desc'), limit(reteriveCounts));
  }
  return getDocs(queryResponse);
};

const onMessageModified = (messages: Ref<any[]>, newEntry: any) => {
  const msgIndex = messages.value.findIndex((msg) => msg.id === newEntry.id);
  if (msgIndex > -1) {
    Object.assign(messages.value[msgIndex], newEntry);
  }
  return messages.value;
};
const onConversationModified = (conversation: Ref<IConversation[]>, newEntry: IConversation) => {
  const convoIndex = conversation.value.findIndex((convo) => convo.id === newEntry.id);
  if (convoIndex > -1) {
    Object.assign(conversation.value[convoIndex], newEntry);
  }
  return conversation.value;
};

const listenToMessages = async (conversationId: string, lastDate: string) => {
  const lastId = lastDate.split('T')[0];
  const messages = ref<DocumentSnapshot<DocumentData>[]>([]);
  let unsubscribeConversation = ref<any>(null);
  const q = (doc(getFirestore(), Collections.CONVERSATIONS, conversationId, Collections.MESSAGES, lastId));
  return new Promise((res, reject) => {
    unsubscribeConversation = onSnapshot(q, (change) => {
      const { id } = change;
      const msgIndex = messages.value.findIndex((msg) => msg.id === id);
      if (msgIndex > -1) {
        Object.assign(messages.value[msgIndex], change);
      } else if (change.id) {
        messages.value.unshift(change);
      }
      res({ messages, unsubscribeConversation });
    },
    (error) => {
      console.log(error);
      reject(error);
    });
  });
};

const listenToConversation = (homeId: string, buyerId: string) => {
  const conversations = ref<IConversations[]>([]);
  const unsubscribe = ref<any>(null);
  const subscribeUser = ref<any>([]);
  const isListenerActivated = ref(false);
  const activateListener = () => {
    isListenerActivated.value = true;
    const buyerRef = doc(getFirestore(), `${Collections.HOMEBUYERS}/${buyerId}`);
    const homeRef = doc(getFirestore(), `${Collections.HOMES}/${homeId}`);
    const q = query(collection(getFirestore(), Collections.CONVERSATIONS), where('buyerReference', '==', buyerRef), where('homeReference', '==', homeRef));
    unsubscribe.value = onSnapshot(q, async (snapshot) => {
      await snapshot.docChanges().forEach(async (change) => {
        const { id } = change.doc;
        const data = { id, ...change.doc.data() } as IConversations;
        if (change.type === 'added') {
          data.messages = ref<any>([]);
          if (data.lastMessageDateTime) {
            const { messages: userMessages, unsubscribeConversation } = (await listenToMessages(id, data.lastMessageDateTime)) as any;
            subscribeUser.value.push(unsubscribeConversation);
            data.messages = userMessages;
          }
          conversations.value.push(data as any);
        } else if (change.type === 'modified') {
          const changeIndex = conversations.value.findIndex((convo) => convo.id === id);
          if (changeIndex > -1) {
            if (getFullDate(conversations.value[changeIndex].lastMessageDateTime) !== getFullDate(data.lastMessageDateTime)
              && data.lastMessageDateTime) {
              const { messages: userMessages, unsubscribeConversation } = (await listenToMessages(id, data.lastMessageDateTime)) as any;
              subscribeUser.value.push(unsubscribeConversation);
              userMessages.value.unshift(...conversations.value[changeIndex].messages);
              conversations.value[changeIndex].messages = userMessages;
            }
            Object.assign(conversations.value[changeIndex], data);
          }
        }
      });
      await store.dispatch(StoreActions.SET_MESSAGES_SKELETON, true);
    },
    (error) => {
      console.log(error);
    });
  };
  const deactivateListener = () => {
    (unsubscribe.value)();
    isListenerActivated.value = false;
  };
  return {
    messages: conversations,
    deactivateListener,
    activateListener,
    isListenerActivated,
    unsubscribe,
    subscribeUserListeners: subscribeUser,
  };
};

export const resetReadCount = async (conversationId: string) => {
  const conversationRef = doc(getFirestore(), `${Collections.CONVERSATIONS}/${conversationId}`);
  const conversationSnapshot = await getDoc(conversationRef);
  const conversationData = conversationSnapshot.data()! as IConversation;
  await updateDoc(conversationRef, {
    unreadMessages: {
      ...conversationData.unreadMessages,
      buyer: 0,
    },
  });
};

const updateConversation = async (conversationId: string, dateTime: string): Promise<void> => {
  const conversationRef = doc(getFirestore(), `${Collections.CONVERSATIONS}/${conversationId}`);
  const conversationSnapshot = await getDoc(conversationRef);
  const conversationData = conversationSnapshot.data()! as IConversation;
  let updatedConversation = {
    ...conversationData,
    lastMessageDateTime: dateTime,
  };
  if (conversationData.firstMessageDateTime === '') {
    updatedConversation = { ...updatedConversation, firstMessageDateTime: dateTime };
  }
  await updateDoc(conversationRef, updatedConversation);
};

const updateTotalConversation = async (): Promise<void> => {
  const conversationsCountDoc = doc(getFirestore(), Collections.TOTAL_RECORDS, Collections.CONVERSATIONS);
  const conversationsCount = (await getDoc(conversationsCountDoc));
  const count = conversationsCount.exists() ? conversationsCount.data().count : 0;
  await setDoc(conversationsCountDoc, {
    count: count + 1,
  });
};

const createConversation = async (adminData: ConversationAdminData, buyerData: ConversationBuyerData, homeData: HomeData) => {
  const conversationObj = FirestoreConversation.toFirestore(adminData, buyerData, homeData);
  const newConversation = doc(collection(getFirestore(), Collections.CONVERSATIONS));
  await setDoc(newConversation, conversationObj, { merge: true });
  return { ...conversationObj, id: newConversation.id } as IConversation;
};

const postConversation = async (
  adminData: ConversationAdminData,
  homeData: any,
  buyerData: any,
) => {
  const [newConversation] = await Promise.all([createConversation(adminData, buyerData, homeData), updateTotalConversation()]);
  const conversation = new FirestoreConversation({ ...newConversation });
  return conversation.id;
};

const setMessage = async (messageBody: IChat, messageDocumentRef: DocumentReference): Promise<void> => {
  const messageDoc = await getDoc(messageDocumentRef);
  let messageFirestoreDocument = {
    messages: [messageBody],
    createdAt: messageBody.dateTime,
    count: 1,
  };
  if (messageDoc.exists()) {
    const messageFromFirestore = messageDoc.data() as ConversationMessageFirestoreDocument;
    messageFromFirestore.messages.push(messageBody);
    messageFirestoreDocument = {
      ...messageFromFirestore,
      count: messageFromFirestore.count + 1,
    };
    await updateDoc(messageDocumentRef, messageFirestoreDocument);
  } else {
    await setDoc(messageDocumentRef, messageFirestoreDocument);
  }
};

const sendNewMessage = async (conversationId: string, messageBody: IChat) => {
  const lastDateTime = messageBody.dateTime;
  const date = getFullDate(lastDateTime);
  const messageDocumentRef = doc(getFirestore(), `${Collections.CONVERSATIONS}/${conversationId}/${Collections.MESSAGES}`, date);
  await setMessage(messageBody, messageDocumentRef);
  await updateConversation(conversationId, lastDateTime);
  return { data: { ...messageBody } };
};

export {
  listenToMessages,
  listenToConversation,
  sendNewMessage,
  postConversation,
  paginateMessage,
};
