import { Document } from '@/models';
import { get, post } from './base-api';

const getDocumentUrl = async (document:Document) => {
  const url = 'getDownloadUrl';
  const params = {
    fileName: document.fileLocation,
  };
  const response = await get(url, { params });
  return response[0];
};

const getDocuments = (body = {}) => post('/documents', body, {});

const getWarrantyDocuments = (body = {}) => post('documents/warrantyDocuments', body, {});

export {
  getDocumentUrl,
  getDocuments,
  getWarrantyDocuments,
};
