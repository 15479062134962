export const InvalidCredentials = (attemptsLeft?: number) => ({
  message: 'This sign in information is incorrect. Please try again, or click Forgot Password to recover a lost password',
  linkText: '',
  link: '',
});

export const emailNotFound = () => ({
  message: "This user doesn't exist.",
});

export const InvalidEmail = () => ({
  message: 'Please enter a valid email.',
});

export const TooManyFailedAttempts = () => ({
  message: 'Too many failed login attempts. Please Contact Us for assistance.',
  linkText: '',
  link: '',
});

export const LockedAccount = () => ({
  message: 'Your account has been locked due to too many failed login attempts. ',
  linkText: 'Contact Us ',
  link: 'https://www.williamryanhomes.com/contact-us/chicago',
});

export const AuthExceptions = {
  NotAuthorizedException: 'NotAuthorizedException',
  UserLambdaValidationException: 'UserLambdaValidationException',
  UserNotFoundException: 'UserNotFoundException',
  InvalidPasswordException: 'InvalidPasswordException',
};

const FirebaseErrors = {
  UserNotFoundError: 'auth/user-not-found',
  TooManyRequestsError: 'auth/too-many-requests',
  InvalidPassword: 'auth/wrong-password',
  AccountLockedError: '',
};

const ValidationMessages = {
  PasswordMismatchError: 'Passwords do not match, please correct.',
  SamePasswordError: 'The New Password entered should not match the Old Password .',
  InvalidPasswordError: 'Please choose a more secure password. It should be minimum 8 characters long , contain at least one number and upper case letter.',
  InvalidOldPassword: 'Old password value is incorrect. Please try again.',
  InvalidPhoneNumberError: 'Please enter a valid Phone Number.',
  PhoneNumberRequiredError: 'The Phone Number field cannot be blank. Please enter a valid Phone Number.',
  InvalidEmailError: 'Please enter a valid email id.',
  BlankEmailError: 'The email address field cannot be blank. Please enter a valid email address.',
};

const EmailSentSuccessfully = 'MAIL_SENT_SUCCESSFULLY';
const UserNotFound = 'EMAIL_NOT_FOUND';

const addAddtionalBuyerResponse = {
  create: 'Buyer Created',
  update: 'Buyer Updated',
};

export {
  FirebaseErrors,
  EmailSentSuccessfully,
  UserNotFound,
  ValidationMessages,
  addAddtionalBuyerResponse,
};
