import { get, post } from './base-api';

const getDivisions = async () => {
  const response = await get('/customerCare/divisions', {});
  return response;
};

const postContactUsForm = (body = {}) => post('/customerCare/contactUs', body, {});

export { getDivisions, postContactUsForm };
