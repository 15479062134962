import { MyAccountTab, Routes } from './enums';

const SidebarNavigationList = [
  {
    name: 'Dashboard',
    routeName: Routes.DASHBOARD,
    sidebar: true,
    showUnreadMessage: false,
    showUnseenPosts: false,
  },
  {
    name: 'My Homes',
    routeName: Routes.HOMES,
    sidebar: false,
    showUnreadMessage: false,
    showUnseenPosts: false,
  },
  {
    name: 'Timeline',
    routeName: Routes.TIMELINE,
    sidebar: true,
    showUnreadMessage: false,
    showUnseenPosts: false,
  },
  {
    name: 'My Messages',
    routeName: Routes.MESSAGES,
    sidebar: true,
    showUnreadMessage: true,
    showUnseenPosts: false,
  },
  {
    name: 'My Photos',
    routeName: Routes.PHOTOS,
    sidebar: true,
    showUnreadMessage: false,
    showUnseenPosts: true,
  },
  {
    name: 'My Documents',
    routeName: Routes.DOCUMENTS,
    sidebar: true,
    showUnreadMessage: false,
    showUnseenPosts: false,
  },
  {
    name: 'Warranty',
    routeName: Routes.WARRANTY,
    sidebar: true,
    showUnreadMessage: false,
    showUnseenPosts: false,
  },
  {
    name: 'My Account',
    routeName: Routes.MY_ACCOUNT,
    sidebar: true,
    showUnreadMessage: false,
    showUnseenPosts: false,
  },
];

const MyAccountTabList = [
  {
    name: 'View My Contact Info',
    key: MyAccountTab.UPDATE_CONTACT_INFO,
  },
  {
    name: 'Change My Password',
    key: MyAccountTab.CHANGE_PASSWORD,
  },
  {
    name: 'Add Additional User',
    key: MyAccountTab.ADD_ADDITIONAL_USER,
  },
];

export {
  SidebarNavigationList,
  MyAccountTabList,
};
