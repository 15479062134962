import { Routes } from '@/constants';

const routes = [
  {
    path: '/login',
    name: Routes.LOGIN,
    component: () => import(/* webpackChunkName: "login" */ '../views/login/Login.vue'),
    meta: {
      requiresRedirect: true,
    },
  },
  {
    path: '/forgot',
    name: Routes.RESET_PASSWORD,
    component: () => import(/* webpackChunkName: "reset" */ '../views/forgot-password/Forgot.vue'),
    meta: {
      requiresRedirect: true,
    },
  },
  {
    path: '/contact-us',
    name: Routes.CONTACT_US,
    component: () => import(/* webpackChunkName: "reset" */ '../views/customer-care/CustomerCare.vue'),
    meta: {
      requiresRedirect: true,
    },
  },
  {
    path: '/',
    name: Routes.DASHBOARD,
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/home/Homes.vue'),
    meta: {
      layout: 'DashboardLayout',
      requiresAuth: true,
    },
  },
  {
    path: '/home',
    name: Routes.HOMES,
    component: () => import(/* webpackChunkName: "select-home" */ '../views/select-home/Home.vue'),
    meta: { layout: 'DashboardLayout' },
  },
  {
    path: '/timeline',
    name: Routes.TIMELINE,
    component: () => import(/* webpackChunkName: "timeline" */ '../views/timeline/Timeline.vue'),
    meta: {
      layout: 'DashboardLayout',
      requiresAuth: true,
    },
  },
  {
    path: '/messages',
    name: Routes.MESSAGES,
    component: () => import(/* webpackChunkName: "messages" */ '../views/messages/Messages.vue'),
    meta: {
      layout: 'DashboardLayout',
      requiresAuth: true,
    },
  },
  {
    path: '/photos',
    name: Routes.PHOTOS,
    component: () => import(/* webpackChunkName: "photos" */ '../views/photos/Photos.vue'),
    meta: {
      layout: 'DashboardLayout',
      requiresAuth: true,
    },
  },
  {
    path: '/documents',
    name: Routes.DOCUMENTS,
    component: () => import(/* webpackChunkName: "documents" */ '../views/documents/Documents.vue'),
    meta: {
      layout: 'DashboardLayout',
      requiresAuth: true,
    },
  },
  {
    path: '/warranty',
    name: Routes.WARRANTY,
    component: () => import(/* webpackChunkName: "warranty" */ '../views/warranty/Warranty.vue'),
    meta: {
      layout: 'DashboardLayout',
      requiresAuth: true,
    },
  },
  {
    path: '/myaccount',
    name: Routes.MY_ACCOUNT,
    component: () => import(/* webpackChunkName: "myaccount" */ '../views/my-account/MyAccount.vue'),
    meta: {
      layout: 'DashboardLayout',
      requiresAuth: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: Routes.ERROR,
    component: () => import(/* webpackChunkName: "404" */ '../views/404/PageNotFound.vue'),
  },
];

export default { routes };
