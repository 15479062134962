import {
  DocumentReference,
} from 'firebase/firestore';
import { AssignedBuyers, IHomebuyer } from './homebuyer';

export interface IPartialHome {
  id: string;
  name: string;
  address: string;
  lotNumber: string;
  zip: string;
  block: string;
}

export interface IDetails {
  id: string;
  name: string;
}
export interface ReqHomesDocument extends IPartialHome {
  community: IDetails;
  communityId: string;
  division: IDetails;
  divisionId: string;
  elevation: string;
  elevationURL: string;
  homebuyers: AssignedBuyers[];
  area: string;
  lastAnnouncementRead: string;
  state: string;
  county: string;
  planNumber: string;
  planDescription: string;
  planElevationNumber: string;
  planElevationDescription: string;
  isWarrantyByPassed: {sixtyDay: boolean, elevenMonth: boolean};
  block: string;
}

export interface Homes {
  reqHomesDocument: ReqHomesDocument[];
  totalHomes: number;
}

export interface ConversationHomeData extends IPartialHome{
  communityName: string;
  divisionName: string;
  closedDate?: string
}

export interface HomeData extends ConversationHomeData {
  divisionReference: DocumentReference;
  communityReference: DocumentReference;
}

export interface HomeBuyerDetails {
  homeBuyerId: string;
  homeBuyer: IHomebuyer;
  homes: Homes;
  maxAdditionalUsersAllowed: number;
  specialCharacterNotAllowed: string;
}

export class Home implements HomeData {
  id!: string;

  name!: string;

  address!: string;

  lotNumber!: string;

  zip!: string;

  block!: string;

  communityName!: string;

  divisionName!: string;

  divisionReference!: DocumentReference;

  communityReference!: DocumentReference;

  constructor(data: HomeData) {
    Object.assign(this, data);
  }

  static toFirestore(h: HomeData): ConversationHomeData {
    return {
      id: h.id,
      name: h.name,
      address: h.address,
      communityName: h.communityName,
      divisionName: h.divisionName,
      lotNumber: h.lotNumber,
      zip: h.zip,
      block: h.block,
      closedDate: h.closedDate,
    };
  }
}
