<template>
  <section class="h-full flex flex-col">
    <slot />
  </section>
</template>

<script lang="ts" setup>
</script>

<style scoped>
</style>
