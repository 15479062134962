import { get, post } from './base-api';

const lockedUserStatus = (params = {}) => get('login/checkLockedUser', { params });

const updateUserStatus = (params = {}) => get('login/updateUserStatus', { params });

const triggerResetPasswordMail = (body = {}) => post('login/updatePasswordMail', body, {});

const updateFailedLoginAttempts = (params = {}) => get('login/updateUserLoginAttempts', { params });

const updateSuccessLogin = (params = {}) => get('login/updateSuccessAttempt', { params });

const addAdditonalBuyer = (body = {}) => post('buyer/addAdditionalBuyer', body, {});

const buyerDetails = (params = {}) => get('buyer/details', { params });

const adminLoginDetails = (email: string, params = {}) => get(`login/authenticateAdminToken/${email}`, { params });

const apiPasswordReset = async (email: string, params = {}) => {
  const response = await get('login/forgetPassword', {
    params: {
      email: email.toLowerCase(),
      ...params,
    },
  });
  if (response) {
    return response;
  }
};

export {
  lockedUserStatus,
  updateUserStatus,
  updateFailedLoginAttempts,
  updateSuccessLogin,
  buyerDetails,
  addAdditonalBuyer,
  triggerResetPasswordMail,
  apiPasswordReset,
  adminLoginDetails,
};
