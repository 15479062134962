import { createApp } from 'vue';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import { init } from './firebase';
/* eslint-disable */
import 'windi.css';
import './assets/postCss/font.css';
/* eslint-enable */
import DashboardLayout from './layout/DashboardLayout.vue';
import Default from './layout/Default.vue';

init();

let app: any;
const auth = getAuth();

onAuthStateChanged(auth, (user) => {
  if (!app) {
    app = createApp(App);
    app.component('DashboardLayout', DashboardLayout);
    app.component('Default', Default);
    app.use(store).use(router).mount('#app');
  }
});
