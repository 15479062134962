import axios from 'axios';
import { Auth, User, getAuth } from 'firebase/auth';
import { ref } from 'vue';
import { AES, enc } from 'crypto-js';
import { useRouter } from 'vue-router';
import {
  Milestones, MyTimeLineStatus, Routes, StoreActions,
} from '@/constants';
import { IMilestone, ITimeline } from '@/models';
import { defaultHomeImage } from './placeholders';
import store from '@/store';

const router = useRouter();
const encryptionKey = process.env.VUE_APP_ENCRYPTION_KEY || '';

const decryptToJSON = (cipherText: string) => {
  if (!cipherText || cipherText === 'null') {
    return null;
  }
  const bytes = AES.decrypt(cipherText, encryptionKey);
  return JSON.parse(bytes.toString(enc.Utf8));
};

const downloadData = async (url: string, label: string, extension:string) => {
  const response = await axios.get(url, { responseType: 'blob' });
  const blob = new Blob([response.data], { type: response.headers['content-type'] });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = `${label}.${extension}`;
  link.click();
  URL.revokeObjectURL(link.href);
};

const useAuth = async () => {
  const auth = ref<Auth | null>(null);
  const user = ref<User | null>(null);
  const isEmailVerified = ref();
  try {
    await getAuth().currentUser?.reload().catch((error) => {
      router.push({ name: Routes.LOGIN });
    });
    user.value = getAuth().currentUser;
    if (user.value) {
      isEmailVerified.value = user.value?.emailVerified;
    }
    return {
      auth, user, isEmailVerified,
    };
  } catch {
    router.push({ name: Routes.LOGIN });
    return {
      auth: ref(null), user: ref(null), isEmailVerified: ref(false),
    };
  }
};

const checkStringEquality = (str1: string, str2: string) => str1.toLowerCase().localeCompare(str2.toLowerCase()) === 0;

const formatPhoneNumber = (number: string) => number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

const isMobile = () => (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));

const formatDate = (completionDate: string): string => {
  const date = new Date(completionDate);
  return date.toLocaleDateString();
};

const showMortage = (milestones: IMilestone[]): boolean => {
  const isPassedContract = milestones && milestones.some((milestone) => {
    if (milestone.name.toLowerCase() === Milestones.CONTRACT && milestone.status === MyTimeLineStatus.DONE) {
      return true;
    }
    return false;
  });
  return isPassedContract;
};

const updateMilestoneStatus = (milestone: ITimeline) => {
  let lastStatus = '';
  const response = milestone.milestones.map((element, index) => {
    const status = element.status!;
    if (index) {
      const isCurrent = element.steps.some((step) => step.status === MyTimeLineStatus.DONE);
      if ((isCurrent || (lastStatus !== status)) && status !== MyTimeLineStatus.DONE) {
        element.status = MyTimeLineStatus.CURRENT;
      }
    } else if (status === MyTimeLineStatus.PENDING) {
      element.status = MyTimeLineStatus.CURRENT;
    }
    lastStatus = status;
    return element;
  });
  return {
    percentage: milestone.percentage,
    milestones: response,
  };
};

const handleImageError = (event: any) => {
  (event.target as HTMLImageElement).src = defaultHomeImage();
};

const getInitials = (firstName = '', lastName = '') => `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`.trim();

const getUserAvatar = (firstName = '', lastName = '') => ({ firstName, lastName });

const showLoader = (value: boolean) => {
  store.dispatch(StoreActions.UPDATE_LOADER, value);
};

const getImpersonationParams = (params = {}) => {
  const { isLoginAsAdmin } = store.getters;
  const impersonatedBuyerEmail = isLoginAsAdmin ? { buyerEmail: localStorage.getItem('impersonatedBuyerEmail') } : {};
  return {
    ...impersonatedBuyerEmail,
    ...params,
  };
};

const endImpersonationSession = () => {
  store.dispatch(StoreActions.CLEAR_STORE);
  localStorage.clear();
  window.close();
};

export {
  handleImageError,
  downloadData,
  useAuth,
  checkStringEquality,
  formatPhoneNumber,
  isMobile,
  formatDate,
  showMortage,
  getInitials,
  getUserAvatar,
  updateMilestoneStatus,
  showLoader,
  decryptToJSON,
  getImpersonationParams,
  endImpersonationSession,
};
