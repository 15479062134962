/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  DocumentData, DocumentReference, QueryDocumentSnapshot, DocumentSnapshot, SnapshotOptions, FirestoreDataConverter,
} from 'firebase/firestore';

export interface UserName {
  firstName: string;
  lastName: string;
}

export interface Url {
  envisionUrl: string;
  designCenterVideo: string;
}
export interface IPartialHomeBuyer {
  uid?: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilephone: string;
  workphone?: string;
  homephone?: string;
  userVerified?: boolean;
  buyerType?: string;
  photoURL?: string;
}

export interface IHomebuyer extends IPartialHomeBuyer {
  communities: DocumentReference;
  divisions: DocumentReference;
  unseenPosts: number;
}

export interface BuyerDetails extends IPartialHomeBuyer {
  type: string;
  buyerType?: string;
}

export interface AssignedBuyers {
  homeBuyerId: string;
  type: string;
}

export class Homebuyer implements IPartialHomeBuyer {
  uid?: string;

  firstName!: string;

  lastName!: string;

  email!: string;

  mobilephone!: string;

  photoURL?: string;

  workphone?: string;

  homephone?: string

  userVerified?: boolean;

  buyerType?: string;

  constructor(data: IPartialHomeBuyer) {
    Object.assign(this, data);
  }

  toString(): string {
    return `Homebuyer - ${this.firstName}`;
  }

  static fromFirestore(
    snapshot: QueryDocumentSnapshot | DocumentSnapshot<DocumentData>,
    options?: SnapshotOptions,
  ): Homebuyer {
    const data = snapshot.data(options)!;
    return new Homebuyer({
      uid: snapshot.id,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      mobilephone: data.mobilephone,
      workphone: data.workphone,
      homephone: data.homephone,
      photoURL: data.photoURL,
      buyerType: data.buyerType,
      userVerified: data.userVerified,
    });
  }

  static toFirestore(h: Homebuyer): DocumentData {
    return {
      firstName: h.firstName,
      lastName: h.lastName,
      email: h.email,
      mobilephone: h.mobilephone,
      workphone: h.workphone,
      homephone: h.homephone,
      photoURL: h.photoURL,
    };
  }
}
