export type PostDocumentData = {
  homeId: string;
  communityId?: string;
  divisionId: string;
  title: string;
  uploadedBy: string;
  uploadedDate: string;
  description: string;
  images: string[];
}

export class Post implements PostDocumentData {
  homeId!: string;

  communityId?: string;

  divisionId!: string;

  title!: string;

  uploadedBy!: string;

  uploadedDate!: string;

  description!: string;

  images!: string[];

  constructor(data: PostDocumentData) {
    Object.assign(this, data);
  }

  static fromFirestore(
    data: PostDocumentData,
  ): Post {
    return new Post({
      homeId: data.homeId,
      communityId: data.communityId,
      divisionId: data.divisionId,
      // type: data.type,
      title: data.title,
      uploadedBy: data.uploadedBy,
      uploadedDate: data.uploadedDate,
      description: data.description,
      images: data.images,
    });
  }
}

export type LightBoxData = {
  selectedIndex: number,
  selectedPost: number,
}

export type GridPost = {
  image: string,
  createdDate: string,
  caption: string
}

export type DesignCenterImage = {
  caption: string,
  id: string,
  url: string,
}
